body {
	--button-border-radius: 40px;
	--button-bg: Transparent;
	--button-stroke: #EDE6C8;
	--button-stroke-width: 5px;
	--button-text: #000;
	--button-bg-hover: #d8d4cf;
	--button-stroke-hover: #000;
	--button-stroke-width-hover: 1px;
	--button-text-hover: #fff;
	--cursor-stroke: #fff;
    --cursor-fill: #fff;
    --cursor-stroke-width: 1px;
	--button-filler: #000;
	--color-bg: #ccc;
}

.button {
	cursor: pointer;
	-moz-appearance: none;
	-webkit-appearance: none;
	border-width: var(--button-stroke-width);
	border-color: var(--button-stroke);
	border-style: solid;
	color: var(--button-text);
	background: var(--button-bg);
	border-radius: var(--button-border-radius);
	width: 10rem;
	padding: 0;
	margin: 0.5rem;
	font-family: inherit;
	font-size: 1.5rem;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}

#steam-button {
  background: #167FAE;
}

#itch-button {
  background: #FA5C5C;
}

#gamejolt-button {
  background: #CCFF00;
}

#discord-button {
  background: #7289DA;
}

#presskit-button {
  background: #AAB9BF;
}

.button:focus,
.button--hover {
	outline: none;
	border-width: var(--button-stroke-width-hover);
	border-color: var(--button-stroke-hover);
	color: var(--button-text-hover);
  fill: var(--button-text-hover);
}

.button__filler {
	background: var(--button-filler);
	position: absolute;
	width: 150%;
	height: 200%;
	border-radius: 50%;
	top: -50%;
	left: -25%;
	transform: translate3d(0,75%,0);
}

.button__text,
.button__text-inner {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 70px;
  text-decoration: none;
  font-size: 50px;
}

a,
a:link,
a:visited,
a:hover,
a:active .button__text-inner{
  text-decoration: none;
}

.cursor {
  visibility: hidden;
}

@media (max-width: 600px) {
  .button {
    width: 6rem;
  }

  .button__text,
  .button__text-inner {
    font-size: 30px;
  }
}
